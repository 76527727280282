




import { Component, Vue } from "vue-property-decorator";
import Fabbricati from "@/components/pages/fabbricati/Fabbricati.vue";

@Component({
  components: {
    Fabbricati,
  },
})
export default class SportelloFabbricatiView extends Vue {}
