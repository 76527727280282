




import { Component, Vue } from "vue-property-decorator";

import OperatnBaseMenu, { MenuItem } from '@/components/gears/bases/OperatnBaseMenu.vue';

@Component({
  components: {
    OperatnBaseMenu
  }
})
export default class MenuSportello extends Vue {
  /* DATA */

  private items: MenuItem[] = [
    {
      icon: "mdi-bed",
      text: "Ricerca Stanze",
      path: "/sportello/ricerca-stanze",
    },
    {
      icon: "mdi-file-document-edit",
      text: "Controlli",
      path: "/sportello/controlli",
    },
    {
      icon: "mdi-account-heart",
      text: "Quietanzianti",
      path: "/sportello/quietanzianti",
    },
    {
      icon: "mdi-school",
      text: "Tipi studente",
      path: "/sportello/tipi-studente",
    },
    {
      icon: "mdi-note-text-outline",
      text: "Tipi contratto",
      path: "/sportello/tipi-contratto",
    },
    {
      icon: "mdi-cash-multiple",
      text: "Tipi bolletta",
      path: "/sportello/tipi-bolletta",
    },
    {
      icon: "mdi-office-building-outline",
      text: "Dipartimenti UNITN",
      path: "/sportello/dipartimenti-unitn",
    },
    {
      icon: "mdi-home-city",
      text: "Fabbricati",
      path: "/sportello/fabbricati",
    },
    {
      icon: "mdi-door-sliding",
      text: "Portinerie",
      path: "/sportello/portinerie",
    },
    {
      icon: "mdi-table-large",
      text: "Tabellone",
      path: "/sportello/tabellone",
    },
    {
      icon: "mdi-file-document-edit-outline",
      text: "Contratti",
      path: "/sportello/contratti",
    },
    {
      icon: "mdi-account",
      text: "Ospiti",
      path: "/sportello/ospiti",
    },
  ];
}
